import { defineComponent } from 'vue'
import './index.scss'
import { GfrCarousel, GfrCarouselSlide, GfrImage, GfrTitle, ModuleBase } from '#components'
import { moduleBaseProps } from '@/components/module/base'
import desktop from 'assets/images/home-module-dreams-bg.png'
import mobile from '@/assets/images/home-module-dreams-bg-mob.png'
import { sortArrayByKey } from '~/utils'

interface ImageWall {
  name: string
  url: string
  weight: number
}
export default defineComponent({
  name: 'ModuleHomeScreen3',
  props: {
    ...moduleBaseProps,
    title: String,
    subtitle: String,
    images: {
      type: Array as () => ImageWall[],
      default: () => []
    }
  },
  setup(props, { slots }) {
    const carouselSpeedHover = ref(0.5)
    const carouselSpeedOne = ref(1)
    const carouselSpeedTwo = ref(1.5)
    const newImages = computed(() => {
      return sortArrayByKey(props.images, 'weight', 'dec') // down
    })
    const carouselOneData = computed(() => {
      return newImages.value.slice(0, Math.ceil(props.images.length / 2))
    })
    const carouselTwoData = computed(() => {
      return newImages.value.slice(Math.ceil(props.images.length / 2))
    })
    return () => (
      <ModuleBase
        name={props.name}
        class={'module-home-dreams-base'}
        desktopBackground={desktop}
        mobileBackground={mobile}
      >
        <section class={['module-home-dreams']}>
          {props.title && (
            <GfrTitle level={2} class={'com-module-title module-home-dreams-title'}>
              {props.title}
            </GfrTitle>
          )}
          {props.subtitle && (
            <GfrTitle level={3} class={'com-module-subtitle module-home-dreams-subtitle'}>
              {props.subtitle}
            </GfrTitle>
          )}
          <section class={'module-home-dream-carousels'}>
            <GfrCarousel
              loop={true}
              effect={'gallery'}
              gap={`${20 / 16}rem`}
              gallery={carouselSpeedOne.value}
              onMouseover={() => {
                carouselSpeedOne.value = carouselSpeedHover.value
              }}
              onMouseout={() => {
                carouselSpeedOne.value = 1.5
              }}
              offset={{ x: 50, y: 0 }}
            >
              {carouselOneData.value?.map((item: any) => (
                <GfrCarouselSlide class={'module-home-dream-slide'}>
                  <GfrImage
                    class={'module-home-dream-image'}
                    lazy
                    src={item.url}
                    fit="cover"
                    name={item.name}
                    nameShow="override"
                  ></GfrImage>
                </GfrCarouselSlide>
              ))}
            </GfrCarousel>
            <GfrCarousel
              loop={true}
              effect={'gallery'}
              gallery={carouselSpeedTwo.value}
              gap={`${20 / 16}rem`}
              onMouseover={() => {
                carouselSpeedTwo.value = carouselSpeedHover.value
              }}
              onMouseout={() => {
                carouselSpeedTwo.value = 2
              }}
              offset={{ x: 150, y: 0 }}
            >
              {carouselTwoData.value?.map((item: any) => (
                <GfrCarouselSlide class={'module-home-dream-slide'}>
                  <GfrImage
                    class={'module-home-dream-image'}
                    lazy
                    src={item.url}
                    fit="cover"
                    name={item.name}
                    nameShow="override"
                  ></GfrImage>
                </GfrCarouselSlide>
              ))}
            </GfrCarousel>
          </section>
          {slots.default?.()}
        </section>
      </ModuleBase>
    )
  }
})
