export type CarouselEffect = 'carousel' | 'slide' | 'gallery' | 'coverflow' | 'fade'
export type CarouselDirection = 'horizontal' | 'vertical'
export type CarouselXy = { x: number; y: number }
export type CarouselPosition = 'start' | 'center' | 'end'
export interface CarouselAutoplay {
  open: boolean
  delay: number
}
export interface CarouselOptions {
  activeIndex?: number
  duration?: number
  loop?: boolean
  slidePosition?: CarouselPosition
  autoplay?: CarouselAutoplay | boolean
  allowTouchMove?: boolean
  offset?: CarouselXy
  activeClass?: string
  effect?: CarouselEffect
  gallery?: number
  pagination?: boolean | object
  navigation?: boolean | object
}
export default class Carousel {
  el: HTMLElement | null = null // Carousel List Dom
  container: HTMLElement | null = null // Carousel Container Dom
  carousel: HTMLElement | null = null // Carousel Dom
  effect: CarouselEffect = 'slide'
  slidePosition: CarouselPosition
  offset: CarouselXy = { x: 0, y: 0 }
  loop: boolean = false
  autoplay: CarouselAutoplay = { open: false, delay: 3000 }
  direction: CarouselDirection = 'horizontal'
  slides: HTMLElement[] = []
  allowTouchMove: boolean = true
  duration: number = 500
  start: CarouselXy = { x: 0, y: 0 }
  move: CarouselXy = { x: 0, y: 0 }
  translate: number = 0
  touch = false
  activeClass = 'gfr-carousel-slide-active'
  _activeIndex: number = 0 // slide list index
  _realIndex: number = 0 // slide data-index
  _timer: any = null
  autoplaying = false
  vectorValue: number = 0 // touchmove vector one time
  toggling = false
  touchTime: number = 0
  carouselDistance: number = 0
  containerDistance: number = 0
  constructor({
    activeIndex = 0,
    duration = 500,
    loop = true,
    slidePosition = 'center',
    autoplay = true,
    allowTouchMove = true,
    offset = { x: 0, y: 0 },
    activeClass = 'gfr-carousel-slide-active',
    effect = 'carousel'
  }: CarouselOptions = {}) {
    this._activeIndex = activeIndex
    this._realIndex = activeIndex
    this.slidePosition = slidePosition
    this.loop = loop
    this.autoplay = typeof autoplay === 'boolean' ? { open: autoplay, delay: 3000 } : autoplay
    this.autoplaying = this.autoplay.open
    this.allowTouchMove = allowTouchMove
    this.offset = offset
    this.duration = duration
    this.activeClass = activeClass
    this.effect = effect
  }
  init(el: HTMLElement) {
    this.el = el
    this.container = this.el.parentElement
    this.carousel = this.container && this.container.parentElement
    this.slides = Array.from(this.el.children) as HTMLElement[]
    this.carouselDistance =
      this.direction === 'horizontal' ? this.carousel?.offsetWidth || 0 : this.carousel?.offsetHeight || 0
    this.containerDistance =
      this.direction === 'horizontal' ? this.container?.offsetWidth || 0 : this.container?.offsetHeight || 0
    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i]
      slide.setAttribute('data-index', i.toString())
    }
    this.setActiveSlide()
    // 查找el的父级元素
    if (this.loop) {
      this.initLoop()
    }
    if (this.autoplay.open) {
      this.startAutoplay()
    }
    if (this.allowTouchMove) {
      this.initTouchMove()
    }
  }
  get info() {
    return {
      el: this.el,
      container: this.container,
      effect: this.effect,
      slidePosition: this.slidePosition,
      offset: this.offset,
      activeIndex: this.activeIndex
    }
  }
  get activeIndex() {
    return this._activeIndex
  }
  set activeIndex(index: number) {
    this._activeIndex = index
  }
  get realIndex() {
    return this._realIndex
  }
  set realIndex(index: number) {
    this._realIndex = index
  }
  get activeSlide() {
    return this.slides.filter((slide) => {
      return Number(slide.getAttribute('data-index')) === this.realIndex
    })[0]
  }
  get length() {
    return this.slides.length
  }
  getSlide(index: number) {
    return this.slides.find((_, idx) => idx === index)
  }
  getSlideIndex(slide: HTMLElement) {
    return this.slides.findIndex((item) => item === slide)
  }
  getSlideByDataIndex(index: number) {
    return this.slides.find((slide) => Number(slide.getAttribute('data-index')) === index)
  }
  removeActive() {
    if (this.activeSlide.classList.contains(this.activeClass)) {
      this.activeSlide.classList.remove(this.activeClass)
    }
  }
  setActiveSlide() {
    this.removeActive()
    this.realIndex = Number(this.getSlide(this.activeIndex)?.getAttribute('data-index'))
    this.activeSlide.classList.add(this.activeClass)
  }
  toggle(index: number | 'next' | 'prev') {
    console.log('index:', index)
  }
  initLoop() {}
  startAutoplay() {
    this.autoplaying = true
  }
  stopAutoplay() {
    this.autoplaying = false
    clearInterval(this._timer)
  }
  restartAutoplay() {
    if (this.autoplay.open && !this.autoplaying) {
      this.startAutoplay()
    }
  }
  transform(vector: number) {
    this.vectorValue += vector
    this.translate += vector
    this.setTranslate()
  }
  setTranslate() {
    if (this.container) {
      this.direction === 'horizontal'
        ? (this.container.style.transform = `translate3d(${this.translate}px, 0, 0)`)
        : (this.container.style.transform = `translate3d(0, ${this.translate}px, 0)`)
    }
  }
  touchStart(event: TouchEvent | MouseEvent) {
    if (!this.allowTouchMove) return false
    this.touch = true
    this.start.x = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX
    this.start.y = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY
    this.touchTime = new Date().getTime()
    this.documentListener()
  }
  touchMove(event: TouchEvent | MouseEvent) {
    if (!this.touch) {
      return false
    }
    if (this.autoplay.open && this.autoplaying) {
      this.stopAutoplay()
    }
    this.container && (this.container.style.pointerEvents = 'none')
    const end = { x: 0, y: 0 }
    end.x = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX
    end.y = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY
    this.move.x = end.x - this.start.x
    this.move.y = end.y - this.start.y
    this.start.x = end.x
    this.start.y = end.y
    if (Math.abs(this.move.x) > Math.abs(this.move.y)) {
      event.preventDefault()
    }
    this.transform(this.direction === 'horizontal' ? this.move.x : this.move.y)
  }
  touchEnd() {
    if (!this.touch) return false
    this.touch = false
    this.start.x = 0
    this.start.y = 0
    this.move.x = 0
    this.move.y = 0
    this.restartAutoplay()
    this.container && (this.container.style.pointerEvents = 'auto')
  }
  documentListener() {
    document.body.addEventListener('touchmove', this.touchMove.bind(this), { passive: false })
    document.body.addEventListener('mousemove', this.touchMove.bind(this), { passive: false })
    document.body.addEventListener('touchend', this.touchEnd.bind(this))
    document.body.addEventListener('mouseup', this.touchEnd.bind(this))
  }
  removeListener() {
    document.body.removeEventListener('touchmove', this.touchMove.bind(this))
    document.body.removeEventListener('mousemove', this.touchMove.bind(this))
    document.body.removeEventListener('touchend', this.touchEnd.bind(this))
    document.body.removeEventListener('mouseup', this.touchEnd.bind(this))
  }
  initTouchMove() {
    this.carousel?.addEventListener('touchstart', this.touchStart.bind(this), { passive: false })
    this.carousel?.addEventListener('mousedown', this.touchStart.bind(this), { passive: false })
  }
  clear() {
    this.removeListener()
    this.carousel?.removeEventListener('mousedown', this.touchStart.bind(this))
    this.carousel?.removeEventListener('touchstart', this.touchStart.bind(this))

    clearInterval(this._timer)
  }
  onChange() {
    console.log('onChange')
  }
  onPrevChange() {
    console.log('onPrev')
  }
  onNextChange() {
    console.log('onNext')
  }
}
