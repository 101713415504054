// Define a type for the carousel options
import Carousel, { CarouselPosition, CarouselAutoplay } from './carousel'
type CarouselOptions = {
  activeIndex?: number
  duration?: number
  loop?: boolean
  slidePosition?: CarouselPosition
  autoplay?: CarouselAutoplay | boolean
  allowTouchMove?: boolean
}

export default class Slide extends Carousel {
  slideDistance: number = 0
  constructor(options: CarouselOptions) {
    // Set the default value of options
    const defaultOptions: CarouselOptions = {
      activeIndex: 0,
      duration: 500,
      loop: true,
      slidePosition: 'center',
      autoplay: true,
      allowTouchMove: true
    }
    // Override default options with the provided options
    const finalOptions = { ...defaultOptions, ...options }
    super(finalOptions)
  }
  init(el: HTMLElement) {
    super.init(el)
    this.slideDistance = this.carouselDistance
    this.resetTranslate()
  }
  insertSlide(slide: HTMLElement, index: number) {
    this.el && this.el.insertBefore(slide, this.slides[index])
    this.slides = Array.from(this.el?.children || []) as HTMLElement[]
    this.activeIndex = this.getSlideIndex(this.activeSlide)
  }
  toggle(index: number | 'next' | 'prev') {
    if (index === this.realIndex) return false
    if (typeof index === 'number') {
      const elem = this.getSlideByDataIndex(index)
      elem && (index = this.getSlideIndex(elem))
    } else if (index === 'next') {
      index = this.activeIndex + 1 >= this.length ? 0 : this.activeIndex + 1
    } else if (index === 'prev') {
      index = this.activeIndex - 1 < 0 ? this.length - 1 : this.activeIndex - 1
    }
    const dist = index - this.activeIndex
    const vector = this.slideDistance * dist
    this.translate -= vector
    this.activeIndex = index as number
    this.setActiveSlide()
    this.transformSlide(() => {})
  }
  transformSlide(fn: () => void, duration: number = this.duration) {
    if (this.container) {
      this.container.style.transition = `transform ${duration}ms`
      this.container.style.transform = `translate3d(${this.translate}px, 0, 0)`
      this.container.style.pointerEvents = 'none'
      this.toggling = true
      this.stopAutoplay()
      this.container.addEventListener(
        'transitionend',
        () => {
          this.toggling = false
          this.restartAutoplay()
          if (this.container) {
            this.container.style.transition = ''
            this.container.style.pointerEvents = 'auto'
          }
          fn()
          this.loop && this.updateLoopTranslate()
        },
        { once: true }
      )
    }
  }
  touchStart(event: TouchEvent | MouseEvent) {
    if (this.toggling) {
      return false
    }
    super.touchStart(event)
  }
  touchEnd() {
    if (!this.touch) return false
    const originIndex = this.activeIndex
    super.touchEnd()
    if (Math.abs(this.vectorValue) > 0) {
      this.touchTime = new Date().getTime() - this.touchTime
      let multi = Number((this.vectorValue / this.slideDistance).toFixed(1))
      if (Math.abs(multi) % 1 > 0.1) {
        multi = multi > 0 ? Math.ceil(multi) : Math.floor(multi)
      } else {
        multi = multi > 0 ? Math.floor(multi) : Math.ceil(multi)
      }
      if (this.loop) {
        this.activeIndex = this.activeIndex - Math.round(multi)
      } else {
        this.activeIndex =
          this.activeIndex - Math.round(multi) < 0
            ? 0
            : this.activeIndex - Math.round(multi) >= this.length
            ? this.length - 1
            : this.activeIndex - Math.round(multi)
      }
      if (originIndex !== this.activeIndex) {
        this.setActiveSlide()
      }
      // this.setActiveSlide()
      this.translate = -this.slideDistance * this.activeIndex
      this.vectorValue = 0
      this.transformSlide(() => {}, this.touchTime > this.duration ? this.duration : this.touchTime)
    }
  }

  transform(vector: number) {
    super.transform(vector)
    if (this.loop) {
      this.updateLoop()
    } else if (this.translate > 0) {
      this.translate = 0
      this.setTranslate()
    } else if (this.translate < -this.slideDistance * (this.length - 1)) {
      this.translate = -this.slideDistance * (this.length - 1)
      this.setTranslate()
    }
  }
  updateLoop() {
    if (this.translate > 0) {
      this.insertSlide(this.slides[this.length - 1], 0)
      this.translate = -this.slideDistance
      this.setTranslate()
    } else if (this.translate < -this.slideDistance * (this.length - 1)) {
      this.insertSlide(this.slides[0], this.length)
      this.translate = -this.slideDistance
      this.setTranslate()
    }
  }
  updateLoopTranslate() {
    if (this.activeIndex === 0) {
      this.insertSlide(this.slides[this.length - 1], 0)
      this.translate = -this.slideDistance * this.activeIndex
      this.setTranslate()
    } else if (this.activeIndex === this.length - 1) {
      this.insertSlide(this.slides[0], this.length)
      this.translate = -this.slideDistance * this.activeIndex
      this.setTranslate()
    }
  }
  resetTranslate() {
    this.translate = -this.slideDistance * this.activeIndex
    this.setTranslate()
  }
  startAutoplay() {
    super.startAutoplay()
    this._timer = setInterval(() => {
      this.toggle('next')
    }, this.autoplay.delay)
  }
}
