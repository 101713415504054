import { computed, defineComponent, PropType } from 'vue'
import './index.scss'
import Slide from '~/components/gfr/Carousel/class/slide'
import { CarouselAutoplay, CarouselDirection, CarouselEffect } from '~/components/gfr/Carousel/class/carousel'
import Gallery from '~/components/gfr/Carousel/class/gallery'
export default defineComponent({
  name: 'GfrCarousel',
  props: {
    effect: {
      type: String as PropType<CarouselEffect>,
      default: 'slide'
    },
    direction: {
      type: String as PropType<CarouselDirection>,
      default: 'horizontal'
    },
    duration: {
      type: Number,
      default: 500
    },
    loop: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: [Boolean, Object] as PropType<CarouselAutoplay | boolean>,
      default: true
    },
    allowTouchMove: {
      type: Boolean,
      default: true
    },
    activeClass: {
      type: String,
      default: 'gfr-carousel-active'
    },
    offset: {
      type: Object as PropType<{ x: number; y: number }>,
      default: () => ({ x: 0, y: 0 })
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    gap: {
      type: String,
      default: '0px' // like 1rem or 16px
    },
    gallery: {
      type: Number,
      default: 1.2
    },
    pagination: {
      type: Boolean,
      default: true
    },
    navigation: {
      type: Boolean,
      default: true
    }
  },
  emits: ['mouseover', 'mouseout', 'change'],
  setup(props, { slots, emit }) {
    const init = ref(false)
    const carousel = ref<HTMLElement | null>(null)
    const carouselInner = ref<HTMLElement | null>(null)
    const carouselList = ref<HTMLElement>()
    const initCarousel = () => {
      switch (props.effect) {
        case 'slide':
          return new Slide(props)
        case 'gallery':
          return new Gallery(props)
        default:
          return new Slide(props)
      }
    }
    const CarouselElem = reactive(initCarousel())
    const carouselListStyle = computed(() => {
      const style: { gridGap?: string; paddingRight?: string } = {}
      style.gridGap = props.gap
      if (props.loop) {
        style.paddingRight = props.gap
      }
      return style
    })
    watch(
      () => CarouselElem.realIndex,
      (val, oldVal) => {
        emit('change', val, oldVal)
      }
    )
    watch(
      () => props.gallery,
      (val) => {
        CarouselElem instanceof Gallery && (CarouselElem.gallery = val)
      }
    )
    const onMouseover = () => {
      emit('mouseover')
    }
    const onMouseout = () => {
      emit('mouseout')
    }
    onMounted(async () => {
      await nextTick()
      if (carouselList.value) {
        CarouselElem.init(carouselList.value)
        CarouselElem instanceof Gallery && (CarouselElem.gallery = props.gallery)
        init.value = true
      }
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          CarouselElem.stopAutoplay()
        } else {
          CarouselElem.restartAutoplay()
        }
      })
    })
    onUnmounted(() => {
      CarouselElem.clear()
    })
    return () => (
      <div
        ref={carousel}
        class={['gfr-carousel', `gfr-carousel-${props.direction}`, init.value && `gfr-carousel-init`, props.effect]}
        onMouseover={onMouseover}
        onMouseout={onMouseout}
      >
        <div ref={carouselInner} class={['gfr-carousel-inner', `gfr-carousel-inner-${props.direction}`]}>
          <ul
            ref={carouselList}
            class={['gfr-carousel-list', `gfr-carousel-list-${props.direction}`]}
            style={carouselListStyle.value}
          >
            {slots.default?.()}
          </ul>
        </div>
        {props.effect === 'slide' && props.pagination && (
          <div
            class={'gfr-carousel-pagination'}
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            {CarouselElem.length > 1 &&
              Array.from({ length: CarouselElem.length }).map((_, index) => (
                <span
                  class={['gfr-carousel-point', index === CarouselElem.realIndex && 'gfr-carousel-point-active']}
                  onClick={() => {
                    CarouselElem.toggle(index)
                  }}
                ></span>
              ))}
          </div>
        )}
      </div>
    )
  }
})
