// Define a type for the carousel options
import Carousel, { CarouselOptions } from './carousel'
export default class Gallery extends Carousel {
  galleryDistance: number = 0
  animationId: number | null = null
  preTimestamp = 0
  _gallery: number = 1
  constructor(options: CarouselOptions) {
    // Set the default value of options
    const defaultOptions: CarouselOptions = {
      activeIndex: 0,
      duration: 500,
      loop: true,
      slidePosition: 'center',
      autoplay: true,
      allowTouchMove: true,
      pagination: false,
      navigation: false
    }
    // Override default options with the provided options
    const finalOptions = { ...defaultOptions, ...options }
    super(finalOptions)
  }
  init(el: HTMLElement) {
    super.init(el)
    this.galleryDistance = this.direction === 'horizontal' ? this.el?.offsetWidth || 0 : this.el?.offsetHeight || 0
    this.resetTranslate()
  }
  initLoop() {
    const cloneEl = this.el?.cloneNode(true)
    cloneEl && this.container?.appendChild(cloneEl)
  }
  get gallery() {
    return this._gallery
  }
  set gallery(value: number) {
    this._gallery = value * 0.2
  }
  transform(vector: number = 0) {
    if (vector === 0) return
    super.transform(vector)
    if (this.loop) {
      this.updateLoopTranslate()
    } else {
      this.resetTranslate()
    }
  }
  updateLoopTranslate(offset: number = this.direction === 'horizontal' ? this.offset.x : this.offset.y) {
    if (this.translate >= -offset) {
      this.translate = -this.galleryDistance + offset
      this.setTranslate()
    } else if (this.translate <= -this.galleryDistance - offset) {
      this.translate = -offset
      this.setTranslate()
    }
  }
  resetTranslate(offset: number = this.direction === 'horizontal' ? this.offset.x : this.offset.y) {
    if (!this.loop) {
      if (this.translate < -(this.galleryDistance + offset - this.carouselDistance)) {
        this.translate = -(this.galleryDistance + offset - this.carouselDistance)
        this.setTranslate()
      } else if (this.translate > offset || this.translate === 0) {
        this.translate = offset
        this.setTranslate()
      }
    } else {
      this.updateLoopTranslate()
    }
  }

  updateTransform(timestamp: number) {
    if (timestamp - this.preTimestamp >= 1000 / 60) {
      this.transform(-this.gallery)
      this.preTimestamp = timestamp
    }
    this.startAnimation()
  }
  stopAutoplay() {
    super.stopAutoplay()
    this.stopAnimation()
  }
  startAnimation() {
    this.animationId = window.requestAnimationFrame((time) => {
      this.updateTransform(time)
    })
  }
  stopAnimation() {
    if (this.animationId) {
      window.cancelAnimationFrame(this.animationId)
    }
  }

  startAutoplay() {
    super.startAutoplay()
    this._timer = setInterval(() => {
      this.transform(-this.gallery)
    }, 2)
    // this.startAnimation()
  }
}
